import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { dXC_GetCityStateMasterAsync } from '../generated/DataActionExtension.g';
import { IDXC_GetCityStateMasterResponse } from '../generated/DataServiceEntities.g';

/**
 * GetStockistsCityorStateInput class
 */
export class GetStockistsCityOrStateInput implements IActionInput {
    public countrycode: string;

    constructor(countrycode: string) {
        this.countrycode = countrycode;
    }
    public getCacheKey = () =>`StockistsCityorStateRegionInfo${this.countrycode}`;
    public getCacheObjectType = () => 'StockistsCityorStateRegionInfo';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * Creates the input required to make the retail api call
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): GetStockistsCityOrStateInput => {
    const { locale } = inputData.requestContext;
    if (!(inputData.requestContext && locale)) {
        throw new Error('Unable to create country regions input.  Locale is not provided.');
    }

    return new GetStockistsCityOrStateInput(locale);
};

export async function getStockcistsCityorStateAction(input: GetStockistsCityOrStateInput, ctx: IActionContext): Promise<IDXC_GetCityStateMasterResponse> {
    console.log(`CityorState action called`);

    let result: IDXC_GetCityStateMasterResponse = { Result: 'failure' };

    try {
        result = await dXC_GetCityStateMasterAsync({ callerContext: ctx }, input.countrycode);
    } catch (error) {
        console.log(error);
    }
    return Array.isArray(result) ? result[0] : result;
}

export const IGetStockcistsCityorStateAction = createObservableDataAction({
    action: <IAction<IDXC_GetCityStateMasterResponse>>getStockcistsCityorStateAction,
    input: createInput
});
