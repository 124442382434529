import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { dXC_GetStockistsAsync } from '../generated/DataActionExtension.g';
import { IDXC_GetStockistsServiceResponse } from '../generated/DataServiceEntities.g';

/**
 * GetStockistsStoreInput class
 */
export class GetStockistsStoreInput implements IActionInput {
    public countrycode?: string;
    public cityorstate?: string;
    public searchstr?: string;

    constructor(countrycode?: string, cityorstate?: string, searchstr?: string) {
        this.countrycode = countrycode;
        this.cityorstate = cityorstate;
        this.searchstr = searchstr;
    }
    public getCacheKey = () =>`StockistsStoreInfo${this.countrycode}`;
    public getCacheObjectType = () => 'StockistsStoreInfo';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * Creates the input required to make the retail api call
 */
const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): GetStockistsStoreInput => {
    const { locale } = inputData.requestContext;
    if (!(inputData.requestContext && locale)) {
        throw new Error('Unable to create country regions input.  Locale is not provided.');
    }

    return new GetStockistsStoreInput(locale);
};

export async function getStockcistsStoreAction(input: GetStockistsStoreInput, ctx: IActionContext): Promise<IDXC_GetStockistsServiceResponse> {
    console.log(`CityorState action called`);

    let result: IDXC_GetStockistsServiceResponse = { Result: 'failure' };
    const country = input.countrycode || '';
    const cityorstate = input.cityorstate || '';
    const searchstr = input.searchstr || '';
    try {
        result = await dXC_GetStockistsAsync({ callerContext: ctx }, country, cityorstate, searchstr);
    } catch (error) {
        console.log(error);
    }
    return Array.isArray(result) ? result[0] : result;
}

export const IGetStockcistsStoreAction = createObservableDataAction({
    action: <IAction<IDXC_GetStockistsServiceResponse>>getStockcistsStoreAction,
    input: createInput
});
